<script>
import { required } from "vuelidate/lib/validators";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import ProfessionalService from '@/services/professional-service'
/**
 * Professionals Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {

      title: "Professionals",
      items: [
        {
          text: "Admin"
        },
        {
          text: "Professionals",
          active: true
        }
      ],
      professionalsData: [

      ],
      professionals: {
        name: "",
        activity: "",
        active: true,
      },
      submitted: false,
      showmodal: false,

      //Paginacao
      pagination: {
        numberOfElements: 0,
        totalPages: 0,
        totalElements: 0,
        last: true,
        first: true,
        number: 0,
        size: 10,
      },
      paginate: false,

      params: {
        size: 10,
        sort: "name",
        active: true,

      },

      filters: {

        name: "",
        activity: "",
        active: true,


      },
    };
  },

  validations: {
    professionals: {
      name: { required },
      activity: { required },
    }
  },
  created() {

    this.service = new ProfessionalService(this.$http)
    this.charge()
  },
  methods: {
    charge() {
      this.loading = true;
      this.service.list(this.params).then((res) => {
        console.log(res)
        this.professionalsData = res.data.content
        this.pagination.totalPages = res.data.totalPages;
        this.pagination.totalElements = res.data.totalElements;
        this.pagination.last = res.data.last;
        this.pagination.first = res.data.first;
        this.pagination.number = res.data.number;
        this.pagination.size = res.data.size;
        this.pagination.numberOfElements = res.data.numberOfElements;

        this.paginate = res.data.totalPages > 0;
        this.loading = false;
      }), (err) => {
        console.error(err)
      }
    },
    changeSize() {
      this.params.page = 0;
      this.charge();
    },
    gotoPage(pageNum) {
      this.params.page = pageNum;
      if (this.search && this.search.length > 2) {
        this.find();
      } else {
        this.charge();
      }
    },
    edit(professional) {

      this.professionals = professional;
      this.showmodal = true;

    },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        const id = this.professionals.id;
        const name = this.professionals.name;
        const activity = this.professionals.activity;
        const active = this.professionals.active;

        this.service.save({
          id,
          name,
          activity,
          active,
        }).then((ret) => {
          console.log(ret)
          this.charge()
          this.showmodal = false;
        })

      }
      this.submitted = false;
      this.professionals = {};
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showmodal = false;
      this.professionals = {};
    },

    /**
     * Filter the data of search
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    filtrar() {
      this.params.name = this.filters.name;
      this.params.activity = this.filters.activity;


      this.params.page = 0
      this.charge();
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a v-b-toggle.accordion-3 class="text-dark" href="javascript: void(0);">{{ $t("all.filters") }} <i
                      class="mdi mdi-filter-menu mr-2"></i></a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" visible>
                <b-card-body>

                  <div class="row">
                    <div class="form-group col-md-5">
                      <label for="name">Profissional</label>
                      <input id="name" v-model="filters.name" type="text" class="form-control"
                        placeholder="Digite o nome" autocomplete="off" :class="{
      'is-invalid': submitted && $v.event.title.$error,
    }" />
                    </div>
                    <div class="form-group col-md-5">
                      <label for="name">Atividade</label>
                      <input id="activity" v-model="filters.activity" type="text" class="form-control"
                        placeholder="Atividade" autocomplete="off" :class="{
      'is-invalid': submitted && $v.event.title.$error,
    }" />
                    </div>
                    <div class="text-right pt-2 mt-2">
                      <b-button type="button" @click="filtrar" variant="success" class="ml-1">Filtrar</b-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-5">
                      <label for="name">Ocultar Inativos?</label>
                      <b-checkbox id="active" checked="true" @change="charge" v-model="params.active">
                      </b-checkbox>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="card-body">
            <div>
              <a href="javascript:void(0);" class="btn btn-success mb-2" @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Adicionar Novo
              </a>
            </div>
            <div class="table-responsive mt-3">
              <table class="table table-centered datatable dt-responsive nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                <thead class="thead-light">
                  <tr>
                    <th>{{ $t("all.name") }}</th>
                    <th>{{ $t("all.activity") }}</th>
                    <td>{{ $t("all.status") }}</td>
                    <th style="width: 120px;">{{ $t("all.actions") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in professionalsData" :key="index">

                    <td>{{ item.name }}</td>
                    <td>{{ item.activity }}</td>
                    <td>{{ $t("active." + item.active) }}</td>

                    <td>
                      <a href="javascript:void(0);" class="mr-3 text-primary" @click="edit(item)" v-b-tooltip.hover
                        :title="$t('all.edit')">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>

                    </td>
                  </tr>
                </tbody>
              </table>
              <b-card-footer v-if="paginate" class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="row">
                  <div class="col-md-11">
                    <ul class="pagination justify-content-left">
                      <li class="page-item">
                        <a v-if="!pagination.first" type="button" class="page-link btn-dark waves-effect waves-light"
                          @click="gotoPage(pagination.number - 1)">Anterior</a>
                      </li>
                      <li class="page-item">
                        <a v-for="num in pagination.totalPages" :key="num"
                          :class="num - 1 === pagination.number ? 'active' : ''" class="page-link btn-dark waves-effect"
                          @click="gotoPage(num - 1)">{{ num }}</a>
                      </li>
                      <li class="page-item">
                        <a v-if="!pagination.last" type="button" class="page-link btn-dark waves-effect waves-light"
                          @click="gotoPage(pagination.number + 1)">Próxima</a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-1 justify-content-left">
                    <select v-model="params.size" class="form-control" @change="changeSize">
                      <option class="selected" selected>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  </div>
                </div>
              </b-card-footer>
            </div>
          </div>
        </div>
      </div>
    </div>
    
   

    <!-- Modal Antigo-->
    <b-modal id="modal-1" v-model="showmodal" :title="$t('all.professional')" title-class="text-dark font-18"
      hide-footer>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="name">{{ $t("all.name") }}</label>
          <input id="name" v-model="professionals.name" type="text" class="form-control" placeholder="Enter name"
            autocomplete="off" :class="{ 'is-invalid': submitted && $v.professionals.name.$error }" />
          <div v-if="submitted && !$v.professionals.name.required" class="invalid-feedback">{{ $t("all.name") }} is
            required</div>
        </div>
        <div class="form-group">
          <label for="exampleInputAnotations">{{ $t("all.activity") }}</label>
          <input id="activity" v-model="professionals.activity" type="text" name="activity" class="form-control"
            placeholder="Enter activity" autocomplete="off"
            :class="{ 'is-invalid': submitted && $v.professionals.activity.$error }" />
          <div v-if="submitted && $v.professionals.activity.$error" class="invalid-feedback">
            <span v-if="!$v.professionals.activity.required">{{ $t("all.activity") }} is required</span>
          </div>
        </div>
        <div class="form-group">
          <label for="profactive">{{ $t("all.status") }}</label>

          <b-checkbox id="profactive" v-model="professionals.active">
            {{ $t("active." + professionals.active) }}
          </b-checkbox>
          <div v-if="submitted && $v.professionals.activity.$error" class="invalid-feedback">
            <span v-if="!$v.professionals.activity.required">{{ $t("all.activity") }} is required</span>
          </div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">{{ $t("all.save") }}</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t("all.cancel") }}</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>